import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>

            <section className="lets-talk-section fix section-padding section-bg pb-0">
                <div className="container">
                    <div className="lets-talk-wrapper">
                        <h2 className="wow fadeInUp" data-wow-delay=".3s">Have a project in mind? Let’s <br /> work together!</h2>
                        <Link to="/contact" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className="theme-btn wow fadeInUp" data-wow-delay=".5s">Let’s Talk!</Link>
                    </div>
                </div>
            </section>
            {/* Footer Section Start */}
            <footer className="footer-section section-bg fix">
                <div className="container">
                    <div className="footer-widgets-wrapper">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".2s">
                                <div className="single-footer-widget">
                                    <div className="widget-head">
                                        <Link to="/">
                                            <img src="/assets/img/logo/logo-white.png" alt="img" />
                                        </Link>
                                    </div>
                                    <div className="footer-content">
                                        <p>
                                            DevUnicornLabs All Rights Reserved. Designed <br /> with passion, Let's create something <br />
                                            amazing together.
                                        </p>
                                        <div className="social-icon d-flex align-items-center">

                                            <Link
                                                to="https://www.facebook.com/people/DevUnicorn-Labs/61568163595974/"><i className="fab fa-facebook-f" /></Link>
                                            {/* <Link
                                                to="#"><i className="fab fa-twitter" /></Link>
                                            <Link
                                                to="#"><i className="fab fa-youtube" /></Link> */}
                                            <Link
                                                to="https://www.linkedin.com/company/devunicornlabs"><i className="fab fa-linkedin-in" /></Link>
                                            <Link target='_blank' to="https://github.com/Full-stack-Web-Dev-BD"><i className="fab  fa-github" /></Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 ps-lg-3 wow fadeInUp" data-wow-delay=".4s">
                                <div className="single-footer-widget">
                                    <div className="widget-head">
                                        <h3>Quick Links</h3>
                                    </div>
                                    <ul className="list-items">
                                        <li>
                                            <Link to="/about-us" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                                Our Services
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                                Privacy & Policy
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/refund-policy" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                                Refund Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                                Contact Us
                                            </Link>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 ps-lg-5 wow fadeInUp" data-wow-delay=".6s">
                                <div className="single-footer-widget">
                                    <div className="widget-head">
                                        <h3>Office</h3>
                                    </div>
                                    <div className="footer-content">
                                        <p> Block : A, Road : 08, Mirpur - Dhaka, Bangladesh</p>
                                        {/* <div className="contact-text">
                                            <h4 className="pb-3"><Link to="/tel:1614643814">(880) 1614-643814</Link></h4>
                                            <h5><Link to="/mailto:softdev.alamin@gmail.com">softdev.alamin@gmail.com</Link></h5>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="4 col-lg-4 col-md-6 ps-lg-3 wow fadeInUp" data-wow-delay=".8s">
                                <div className="single-footer-widget">
                                    <div className="widget-head">
                                        <h3>Subscribe to our newsletter</h3>
                                    </div>
                                    <div className="footer-content">
                                        <div className="footer-input">
                                            <input type="email" id="email" placeholder="Enter your email" />
                                            <button className="newsletter-btn" type="submit">
                                                <i className="fa-solid fa-paper-plane" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="footer-bottom-wrapper">
                            <p className="wow fadeInUp" data-wow-delay=".3s">Copyright @2024</p>
                            <p className="wow fadeInUp" data-wow-delay=".5s">Designed By <a target='_blank' href="https://www.upwork.com/freelancers/~01e8847c146c768034">Shakhawat H.</a></p>
                        </div>
                    </div>
                </div>
                <hr />
                <div className=' col-md-10 offset-md-1 text-center'>
                    <img src="/assets/img/logo/logo-white.png" alt="logo-img" width={300} className='mb-4' />
                    <p className='mb-3 ' style={{ fontSize: '16px' }}>This site is not part of the Google/Facebook website or Google Inc /Facebook Inc. Additionally, this site is NOT endorsed by Google / Facebook in any way. FACEBOOK is a trademark of FACEBOOK, Inc. We use Google remarketing pixels/cookies on this site to re-communicate with people who visit our site and ensure that we are able to reach them in the future with relevant messages and information. Google shows our ads across third party sites across the internet to help communicate our message and reach the right people who have shown interest in our information in the past</p>
                </div>
            </footer></>
    )
}

export default Footer
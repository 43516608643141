import React from 'react'

const Clients = () => {
  return (
    <div className='mt-5'>
      <div className="brand-section section-padding fix pt-0" bis_skin_checked={1}>
        <div className="container" bis_skin_checked={1}>
          <div className="brand-text" bis_skin_checked={1}>
            <h5>CLIENTS WE’VE WORKED WITH</h5>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-6 col-md-4 col-lg-2 mb-3">
                <img src="/assets/img/brand/01.webp" alt="img" className="img-fluid brand_image" />
              </div>
              <div className="col-6 col-md-4 col-lg-2 mb-3">
                <img src="/assets/img/brand/02.webp" alt="img" className="img-fluid brand_image" />
              </div>
              <div className="col-6 col-md-4 col-lg-2 mb-3">
                <img src="/assets/img/brand/03.webp" alt="img" className="img-fluid brand_image" />
              </div>
              <div className="col-6 col-md-4 col-lg-2 mb-3">
                <img src="/assets/img/brand/04.webp" alt="img" className="img-fluid brand_image" />
              </div>
              <div className="col-6 col-md-4 col-lg-2 mb-3">
                <img src="/assets/img/brand/05.webp" alt="img" className="img-fluid brand_image" />
              </div>
              <div className="col-6 col-md-4 col-lg-2 mb-3">
                <img src="/assets/img/brand/06.webp" alt="img" className="img-fluid brand_image" />
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default Clients
import React from 'react'

const Faq = () => {
    return (
        <div className='section-bg'>
            <div className='faq'>
                {/* Faq Section Start */}
                <section className="faq-section fix section-padding">
                    <div className="container">
                        <div className="brand-text" bis_skin_checked={1}>
                            <h5>(FAQs): Everything You Need to Know </h5>
                        </div>
                        <div className="faq-wrapper">
                            <div className="row g-4">
                                <div className="col-lg-4">
                                    <div className="faq-content-2">
                                        <div className="section-title">
                                            <h2>
                                                Frequently Asked <span> Questions</span>
                                            </h2>
                                            <p className="mt-2">Still have a question in mind? Contact us directly!</p>
                                        </div>
                                        <div className="faq-client-items">
                                            <div className="client-info">
                                                <img src="/assets/img/01.webp" alt="img" />
                                                <div className="content">
                                                    <h4>Guy Hawkins</h4>
                                                    <span>Austin</span>
                                                </div>
                                            </div>
                                            <p>
                                                "<span className='brand_color'>DevUnicornLabs</span> transformed our ideas into a stunning reality. Their innovative approach and dedication to our project  with the results."
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="faq-content">
                                        <div className="faq-accordion">
                                            <div className="accordion" id="accordion">
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".2s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq1" aria-expanded="true" aria-controls="faq1">
                                                            What makes  DevUnicornLabs the best website design company in Bangladesh?
                                                        </button>
                                                    </h5>
                                                    <div id="faq1" className="accordion-collapse show" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            DevUnicornLabs is recognized for our high-quality website designs, personalized service, and innovative solutions. With over 11 years of experience and thousands of satisfied clients, we deliver exceptional results that help businesses grow.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".4s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2" aria-expanded="false" aria-controls="faq2">
                                                            How long does it take to design and develop a website?
                                                        </button>
                                                    </h5>
                                                    <div id="faq2" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            The timeline for website design and development varies based on the complexity of the project. Typically, a standard website can take between 4 to 6 weeks from initial consultation to launch. We ensure timely delivery without compromising on quality.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".6s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" aria-controls="faq3">
                                                            What technologies do you use for website development?
                                                        </button>
                                                    </h5>
                                                    <div id="faq3" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            We use the latest technologies such as HTML5, CSS3, JavaScript, Python frameworks (like React and Angular, Django), and content management systems (like WordPress) to build responsive, high-performance websites that meet modern standards.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".7s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq4" aria-expanded="false" aria-controls="faq4">
                                                            Do you offer website maintenance and support after the launch?
                                                        </button>
                                                    </h5>
                                                    <div id="faq4" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            Yes, we offer comprehensive website maintenance and support services to ensure your website remains up-to-date, secure, and fully functional. Our team is available 24/7 to assist with any issues or updates you may need.

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item wow fadeInUp" data-wow-delay=".8s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq5" aria-expanded="false" aria-controls="faq5">
                                                            What additional benefits do I get with your website design services?
                                                        </button>
                                                    </h5>
                                                    <div id="faq5" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            Along with top-quality website design, we provide additional benefits such as free logo design, social media setup, basic SEO package, one month of free maintenance, one year of free hosting, and a free SSL certificate to ensure your website is secure and optimized for success.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Faq
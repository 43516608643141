import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { FaSquareUpwork } from "react-icons/fa6";

import { toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ContactUs = ({ call }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        location: '',
        businessName: '',
        teamSize: '',
        acceptedPrivacy: false,
    });
    const [sending, setSending] = useState(false);
    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.phone) newErrors.phone = 'Phone number is required';
        if (!formData.location) newErrors.location = 'Location is required';
        if (!formData.businessName) newErrors.businessName = 'Business name is required';
        if (!formData.teamSize) newErrors.teamSize = 'Team size is required';
        if (!formData.acceptedPrivacy) newErrors.acceptedPrivacy = 'You must accept the Privacy Policy';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validate();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            toast.warning("Fill the form correctly !!", { position: 'bottom-center' });
            return;
        }
        setErrors({});
        try {
            setSending(true);
            const response = await axios.post(
                'https://devlabs-production.up.railway.app/api/lead/create-lead',
                formData
            );
            setSending(false);
            if (response.status === 201) {
                toast.success('Thank you for contacting us. Our customer support agent will respond as soon as possible!', { position: 'bottom-center' });
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    location: '',
                    businessName: '',
                    teamSize: '',
                    acceptedPrivacy: false,
                });
            }
        } catch (error) {
            console.log(error);
            toast.warning('Failed to submit the lead. Please try again.', { position: 'bottom-center' });
        }
    };

    return (
        <section className="mt-5 mb-5">
            <div className="container form_hero_wrap">
                <div className="brand-text mb-0">
                    <h5>Innovative Technology we are working on</h5>
                </div>
                <div className="row form_section mt-4">
                    <div className="col-lg-12 offset-lg-0">
                        <div className="row">

                            <div className="col-md-6 wow fadeInUp" data-wow-delay=".5s">
                                <h2 className='mt-5'>Innovative Technology for Superior Websites</h2>
                                <p>
                                    At <span className='brand_color'>DevUnicornLabs</span>, we use the latest technologies to create websites that are not only visually appealing but also functionally robust. From HTML5 and CSS3 to advanced JavaScript frameworks, our tech stack ensures your website stands out in the competitive Bangladesh market.
                                </p>

                                <div className="tech-grid mt-4">
                                    {[
                                        { name: 'Node.js', img: 'nodejs.png' },
                                        { name: 'React', img: 'react.png' },
                                        { name: 'Python', img: 'python.png' },
                                        { name: 'Django', img: 'django.png' },
                                        { name: 'MongoDB', img: 'mongodb.png' },
                                        { name: 'WordPress', img: 'wordpress.png' },
                                        { name: 'WooCommerce', img: 'woocommerce.png' },
                                        { name: 'HTML', img: 'html.png' },
                                        { name: 'CSS', img: 'css.png' },
                                        { name: 'JavaScript', img: 'js.webp' },
                                    ].map((tech, index) => (
                                        <div className="tech-item" key={index}>
                                            <img src={`/assets/img/tech/${tech.img}`} alt={tech.name} className="img-fluid" />
                                            <p>{tech.name}</p>
                                        </div>
                                    ))}
                                </div>

                                {call && (
                                    <div>
                                        <a target='_blank' href="https://www.upwork.com/freelancers/~01e8847c146c768034">
                                            <button className="btn home_call_now"><span>
                                                <FaSquareUpwork />
                                                Book a Free consultation</span></button>
                                        </a>
                                    </div>
                                )}

                            </div>
                            <div className="col-md-6">
                                <form className="form" onSubmit={handleSubmit}>
                                    <div className="form_inner">
                                        <div className="input_group">
                                            <label>
                                                Full Name <span>*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                placeholder="Enter Your Full Name"
                                                value={formData.name}
                                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                            />
                                            {errors.name && <small className="text-danger">{errors.name}</small>}
                                        </div>
                                        <div className="input_group">
                                            <label>
                                                Email <span>*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                placeholder="Enter Your Email"
                                                type="email"
                                                value={formData.email}
                                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                            />
                                            {errors.email && <small className="text-danger">{errors.email}</small>}
                                        </div>
                                        <div className="input_group phone_input">
                                            <label>
                                                Phone <span>*</span>
                                            </label>
                                            <PhoneInput
                                                country={'bd'}
                                                value={formData.phone}
                                                onChange={(phone) => setFormData({ ...formData, phone })}
                                            />
                                            {errors.phone && <small className="text-danger">{errors.phone}</small>}
                                        </div>
                                        <div className="input_group">
                                            <label>
                                                Your Location <span>*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                placeholder="Enter Your Location"
                                                value={formData.location}
                                                onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                                            />
                                            {errors.location && <small className="text-danger">{errors.location}</small>}
                                        </div>
                                        <div className="input_group">
                                            <label>
                                                Your Business Name <span>*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                placeholder="Your Business Name"
                                                value={formData.businessName}
                                                onChange={(e) => setFormData({ ...formData, businessName: e.target.value })}
                                            />
                                            {errors.businessName && <small className="text-danger">{errors.businessName}</small>}
                                        </div>
                                        <div className="input_group">
                                            <label>
                                                Your Company Team Size <span>*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                placeholder="Team Size (ex: 2-10)"
                                                value={formData.teamSize}
                                                onChange={(e) => setFormData({ ...formData, teamSize: e.target.value })}
                                            />
                                            {errors.teamSize && <small className="text-danger">{errors.teamSize}</small>}
                                        </div>
                                        <div className="input_group">

                                            <input
                                            style={{marginRight:'10px'}}
                                                id='accept'
                                                type='checkbox'
                                                checked={formData.acceptedPrivacy}
                                                onChange={() => setFormData({ ...formData, acceptedPrivacy: !formData.acceptedPrivacy })}
                                            />
                                            <label htmlFor='accept' style={{cursor:'pointer'}}>
                                                I agree to the <Link to={'/privacy-policy'} className='link_text'>Privacy Policy.</Link>
                                            </label>
                                            {errors.acceptedPrivacy && <small className="text-danger">{errors.acceptedPrivacy}</small>}
                                        </div>
                                        <div className="input_group">
                                            {sending ? (
                                                <button type="submit" className="btn" style={{ cursor: 'wait' }}>
                                                    Sending your message...
                                                </button>
                                            ) : (
                                                <button type="submit" className="btn">
                                                    Get a FREE Quote
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;

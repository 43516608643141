import React from 'react'
import { Link } from 'react-router-dom'


import "./privacy_policy.css"
const RefundPolicy = () => {
    return (
        <div className=''>
            <div>
                {/* Breadcrumb Section Start */}
                <div className="breadcrumb-wrapper section-bg bg-cover" style={{ backgroundImage: 'url("/assets/img/icon/breadcrumb-shape.webp")' }}>
                    <div className="arrow-shape">
                        <img src="/assets/img/icon/arrow-shape.webp" alt="img" />
                    </div>
                    <div className="circle-shape">
                        <img src="/assets/img/icon/circle-shape.webp" alt="img" />
                    </div>
                    <div className="container">
                        <div className="page-heading">
                            <div className="breadcrumb-sub-title">
                                <h1 className="wow fadeInUp" data-wow-delay=".3s">Privacy & Policy</h1>
                            </div>
                            <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                                <li>
                                    <a href="index.html">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <i className="fa-solid fa-angles-right" />
                                </li>
                                <li>
                                    Privacy & Policy
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="marquee-section fix">
                        <div className="mycustom-marque">
                            <div className="scrolling-wrap">
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Privacy & Policy</div>
                                    <div className="cmn-textslide textitalick">Privacy & Policy</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Privacy & Policy</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Privacy & Policy</div>
                                    <div className="cmn-textslide textitalick">Privacy & Policy</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Privacy & Policy</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Privacy & Policy</div>
                                    <div className="cmn-textslide textitalick">Privacy & Policy</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Privacy & Policy</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 404 Error Section Start */}
                <section className="error-section section-padding fix privacy_policy">
                    <div className="container">
                        <section class="container container--100">
                            <div class="container__col container__col--100" bis_skin_checked="1">
                                <section>
                                    <h2>Refund Policy</h2>
                                    <p>At DevUnicornLabs, we strive to provide top-quality services to all our clients. However, if you're not satisfied with our services, we offer the following refund policy:</p>

                                    <h3 className='mt-4'>1. Website Design and Development Services</h3>
                                    <ul>
                                        <li>We offer a <strong>30-day money-back guarantee</strong> for website design and development services. If you're not satisfied with the initial design concepts or the overall direction of the project, you can request a full refund within 30 days of making the first payment.</li>
                                        <li>Once the project is completed and delivered to you, we do not offer refunds. By this stage, you should have received deliverables and feedback from us to ensure the project is in line with your expectations.</li>
                                        <li>Refund requests must be made in writing (via email) and will be assessed on a case-by-case basis.</li>
                                    </ul>

                                    <h3 className='mt-4'>2. Add-on Services (Hosting, Domain, SEO, etc.)</h3>
                                    <ul>
                                        <li><strong>Domain Name Registration</strong>: As domain names are registered in your name, they are non-refundable once the purchase has been made.</li>
                                        <li><strong>Hosting Services</strong>: If you cancel your hosting service within the first 30 days, you may be eligible for a partial refund, minus any setup fees.</li>
                                        <li><strong>SEO and Marketing Services</strong>: Due to the nature of ongoing SEO services, refunds will not be issued after the work has begun unless specific deliverables (e.g., SEO reports or consultations) were not provided within the agreed-upon timeframe.</li>
                                    </ul>

                                    <h3 className='mt-4'>3. Process for Requesting a Refund</h3>
                                    <ul>
                                        <li>To initiate a refund request, please contact our customer support team via email at <a href="mailto:support@devunicornlabs.com">support@devunicornlabs.com</a> with the subject line "Refund Request."</li>
                                        <li>In your email, provide your full name, order number, and a brief description of the issue you're facing. Our team will review your request and respond within 5-7 business days.</li>
                                    </ul>

                                    <h3 className='mt-4'>4. Conditions for Refund</h3>
                                    <ul>
                                        <li>Refunds are only eligible for payment made by credit card or direct bank transfer. Refunds will not be processed for third-party payments, including those made via PayPal, unless explicitly stated in the agreement.</li>
                                        <li>Refunds will not be provided for services that have already been delivered or for any work that has been completed.</li>
                                    </ul>

                                    <h3 className='mt-4'>5. Disputes</h3>
                                    <p>If you feel that your refund request has not been handled properly, you have the option to dispute the matter. Please contact us directly at <a href="mailto:support@devunicornlabs.com">support@devunicornlabs.com</a>, and we will work with you to resolve any issues.</p>

                                    <h3 className='mt-4'>6. No Refund for Custom Solutions</h3>
                                    <p>For custom business solutions, tailored software, or services that are highly specific to your business needs, no refunds will be offered after the work has commenced.</p>
                                </section>
                                <hr />
                                <section class="page-header page-header--left">

                                    <div class="page-header__content" bis_skin_checked="1">
                                        <h2 class="page-header__title">DevUnicornLabs Privacy Policy</h2>
                                    </div>

                                </section>

                            </div>
                        </section>
                    </div>
                </section>
            </div>

        </div>
    )
}

export default RefundPolicy
import React from 'react'
import { LuFigma } from "react-icons/lu";
import { MdOutlinePayment, MdOutlineSupportAgent } from "react-icons/md";
import { FaShareAlt, FaWordpress, FaGoogle } from "react-icons/fa";

const Features = () => {
  return (
    <div className='features'>
      <div className="brand-section section-padding fix section-bg " bis_skin_checked={1}>
        <div className="container" bis_skin_checked={1}>
          <div className="brand-text" bis_skin_checked={1}>
            <h5> End to end Custom Business Solution Development </h5>
          </div>
          <h2>We're More Than Just a Website Design Company in Bangladesh– We're Your Growth Partner.</h2>
          <p>Unlike other website development companies in Bangladesh, we take a holistic approach. We understand that a website is a crucial asset in your digital marketing strategy. That's why we work closely with you to understand your unique business goals and create a website that attracts, engages, and converts visitors into loyal customers</p>
          <div className="container">
            <div class="services-section">
              <div class="container">
                <div class="row">
                  <div class="col-md-6 col-sm-12 service-item">
                    <div class="service-content">
                      <div class="service-icon">
                        <LuFigma />
                      </div>
                      <div class="service-details">
                        <h3>Dynamic UI/UX Design</h3>
                        <p>
                          A user-friendly and visually appealing website that provides a seamless user experience across all devices.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12 service-item">
                    <div class="service-content">
                      <div class="service-icon">
                        <MdOutlinePayment />
                      </div>
                      <div class="service-details">
                        <h3>Payment Gateway Integration</h3>
                        <p>
                          Accept online payments securely and conveniently with seamless integration of trusted payment gateways.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12 service-item">
                    <div class="service-content">
                      <div class="service-icon">
                        <FaShareAlt />
                      </div>
                      <div class="service-details">
                        <h3>Social Media Integration</h3>
                        <p>
                          Connect with your audience and amplify your brand across all major social media platforms.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12 service-item">
                    <div class="service-content">
                      <div class="service-icon">
                        <FaWordpress />
                      </div>
                      <div class="service-details">
                        <h3>Content Management System</h3>
                        <p>
                          Easily manage your website content with a user-friendly CMS platform, allowing for updates without any technical expertise.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12 service-item">
                    <div class="service-content">
                      <div class="service-icon">
                        <MdOutlineSupportAgent />
                      </div>
                      <div class="service-details">
                        <h3>Chatbot Integration</h3>
                        <p>
                          Enhance user engagement and provide instant customer support with a user-friendly chatbot.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12 service-item">
                    <div class="service-content">
                      <div class="service-icon">
                        <FaGoogle />
                      </div>
                      <div class="service-details">
                        <h3>Search Engine Optimization</h3>
                        <p>
                          We optimize your website for search engines, improving your online visibility and organic traffic.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
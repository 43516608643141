import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Link, Routes } from 'react-router-dom'
import Preloader from "./Components/Preloader"
import PageProperties from './Components/PageProperties'
import Footer from './Components/Footer'
import OffCanvas from './Components/OffCanvas'
import Header from './Components/Header'
import Home from './Page/Home'
import About from './Page/About'
import Service from './Page/Service'
import Project from './Page/Project'
import NotFind from './Page/404'
import Faq from './Page/Faq'
import Contact from './Page/Contact'
import WebDevelopment from './Page/services/WebDevelopment'
import BlockchainDevelopment from './Page/services/BlockchainDevelopment'
import GraphicsDesign from './Page/services/GraphicsDesign'
import DigitalMarketing from './Page/services/DigitalMarketing'




// css

import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';
import { ToastContainer } from 'react-toastify'
import PrivacyPolicyPopup from './Components/PrivacyPolicyPopup'
import PrivacyAndPolicy from './Page/PrivacyPolicy'
import RefundPolicy from './Page/RefundPolicy'

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [expired, setexpired] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setexpired(true)
    }, 1200);

  }, [])




  return (
    <>
      <div className='body-bg'>
        <ToastContainer />
        <div>
          <BrowserRouter>
            {/*Page  Properties */}

            {
              !expired ?
                <Preloader />
                : ''
            }
            <PrivacyPolicyPopup />
            <PageProperties />
            <OffCanvas isOpen={isOpen} setIsOpen={setIsOpen} />
            {/* Header Section Start */}
            <Header isOpen={isOpen} setIsOpen={setIsOpen} />


            <Routes>
              <Route path='/' Component={Home} />
              <Route path='/about-us' Component={About} />
              <Route path='/privacy-policy' Component={PrivacyAndPolicy} />
              <Route path='/refund-policy' Component={RefundPolicy} />
              <Route path='/services' Component={Service} />
              <Route path='/web-development' Component={WebDevelopment} />
              <Route path='/blockchain-development' Component={BlockchainDevelopment} />
              <Route path='/graphics-design' Component={GraphicsDesign} />
              <Route path='/digital-marketing' Component={DigitalMarketing} />
              <Route path='/project' Component={Project} />
              <Route path='/faq' Component={Faq} />
              <Route path='/contact' Component={Contact} />
              <Route path='/404' Component={NotFind} />
              <Route path='*' Component={NotFind} /> 
            </Routes>


            {/* Footer Section Start */}
            <Footer />
          </BrowserRouter>
        </div>

      </div>
    </>

  );
}

export default App;

import React from 'react'
import { Link } from 'react-router-dom'
import Clients from '../Components/Clients'
import { FaAward } from "react-icons/fa";
import { MdOutlineSupportAgent } from "react-icons/md";
import { FaUsersCog } from "react-icons/fa";
import { MdOutlineWorkHistory } from "react-icons/md";
import { FaSquareUpwork, FaSquareWhatsapp } from "react-icons/fa6";


import 'react-phone-input-2/lib/style.css'
import Features from '../Components/Features';
import Offer from '../Components/Offer';
import Faq from './Faq';
import ContactUs from '../Components/ContactUs';

const Home = () => {
    return (
        <>
            <div>
                {/* Hero Section Start */}
                <section className="hero-section hero-1 section-padding">
                    <div className="container">
                        <div className="brand-text top_text" bis_skin_checked={1}>
                            <h5>Deliver web solutions with a team CTOs praise</h5>
                        </div>
                        <div className="hero-content customize_hero">
                            <h1 className="wow fadeInUp" data-wow-delay=".3s" style={{ zIndex: 9, position: 'relative' }}>
                            Affordable Web Development team <br />
                            <img src="/assets/img/team-img.png" alt="img" /> <span>Shakhawat H.</span> From Your Trusted Partner.
                            </h1>


                            {/* next section */}
                            <div className='offer_section '>
                                <div class="wow fadeInUp offer_price" data-wow-delay=".3s">
                                    <p>Starting from <span style={{ color: '#108a00' }}><b>$199/-</b></span> </p>
                                </div>
                                <p >
                                Complete the project on time with a web development team that mastered technologies like React, Node.js, TypeScript, PHP, and AWS. Kickoff work on your web app in just 2-4 weeks.
                                {/* <b style={{ color: '#108a00' }} > boost leads by 200% </b>  and <b style={{ color: '#108a00' }}>sales by 30%. </b> */} Let's turn your online presence into a powerful growth engine.
                                </p>
                                <div className="row mt-4">
                                    <div className="col-md-8  wow fadeInUp" data-wow-delay=".4s">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='our_speciality'>
                                                    <FaAward />
                                                    <p>Award Winning Team</p>
                                                </div>
                                                <div className='our_speciality'>
                                                    <MdOutlineSupportAgent />
                                                    <p>24/7 Support</p>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='our_speciality'>
                                                    <FaUsersCog />
                                                    <p>6+ Years of Expertise</p>
                                                </div>
                                                <div className='our_speciality'>
                                                    <MdOutlineWorkHistory />
                                                    <p>Top Quality Work</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a target='_blank' href="https://www.upwork.com/freelancers/~01e8847c146c768034">
                                    <button className='btn home_call_now'>
                                        <span>
                                            <FaSquareUpwork  />
                                            Book a Free consultation
                                        </span>
                                    </button>
                                </a>
                            </div>
                        </div>
                        <div className="hero-counter float-bob-y ">
                            <div className="counter-items">
                                <h2><span className="count">6</span>+</h2>
                                <p>YEAR EXPERIENCE</p>
                            </div>
                            <div className="counter-items">
                                <h2><span className="">60+</span></h2>
                                <p>HAPPY CUSTOMER</p>
                            </div>
                            <div className="counter-items">
                                <h2><span className="count">4.9</span></h2>
                                <p>⭐⭐RATINGS⭐⭐</p>
                            </div>
                        </div>
                        <div className="circle-item">
                            <div className="circle-text">
                                <img src="./assets/img/rounded.png" width={155} alt="DevUnicornLabs" />
                            </div>
                            <div className="border-line">
                                <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/about-us" className="arrow-icon">
                                    <i className="fa-sharp fa-solid fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </section >

                <section>
                    <ContactUs />
                </section>
                {/* features */}
                <section>
                    <Features />
                </section>
                <section>
                    <Offer />
                </section>
                {/* Brand Section Start */}
                <section>
                    <Faq />
                </section>
                <section>
                    < Clients />
                </section>
                {/* About Section Start */}
                < section className="about-section fix section-padding section-bg" >
                    <div className="container">
                        <div className="section-title-area">
                            <div className="section-title">
                                <h6 className="wow fadeInUp">About Us</h6>
                                <h2 className="wow fadeInUp" data-wow-delay=".3s">Empowering Your <span>Vision </span> <br />
                                    with Tailored Digital Solutions</h2>
                            </div>
                            <p className="wow fadeInUp" data-wow-delay=".5s">
                                Crafting digital web solutions to bring your <br />Business Up.
                            </p>
                        </div>
                        <div className="about-wrapper">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-lg-7">
                                    <div className="about-image-items">
                                        <div className="row g-4 align-items-center">
                                            <div className="col-md-6 wow fadeInUp" data-wow-delay=".3s">
                                                <div className="support-content">
                                                    <h3>24/7 Support</h3>
                                                    <div className="text-area">
                                                        <p>We provide 24/7 service <br /> to our customers</p>
                                                        <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/about" className="icon">
                                                            <i className="fa-sharp fa-solid fa-arrow-right" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="about-image">
                                                    <img src="./assets/img/intro.png" alt="img" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 wow fadeInUp" data-wow-delay=".5s">
                                                <div className="about-image-2">
                                                    <div className="about-image style-2">
                                                        <img src="./assets/img/we.png" alt="img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="about-content">
                                        <div className="about-icon-items wow fadeInUp" data-wow-delay=".3s">
                                            <div className="icon">
                                                <i className="fa-solid fa-dollar-sign" />
                                                <div className="line-bar">
                                                    <img src="/assets/img/icon/line.webp" alt="img" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h3>Our Mission</h3>
                                                <p>
                                                    To empower businesses by delivering innovative, reliable web and app solutions
                                                    that drive growth and success in a digital-first world.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="about-icon-items wow fadeInUp" data-wow-delay=".5s">
                                            <div className="icon">
                                                <i className="fa-regular fa-star" />
                                                <div className="line-bar">
                                                    <img src="/assets/img/icon/line.webp" alt="img" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h3>Our Vision</h3>
                                                <p>
                                                    To become a global leader in digital transformation, helping businesses unlock
                                                    their full potential through cutting-edge technology and exceptional user
                                                    experiences.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="about-icon-items wow fadeInUp" data-wow-delay=".3s">
                                            <div className="icon">
                                                <i className="fa-solid fa-shield-halved" />
                                                <div className="line-bar">
                                                    <img src="/assets/img/icon/line.webp" alt="img" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h3>What Sets Us Apart</h3>
                                                <p>
                                                    Our personalized approach, attention to detail, and commitment to delivering
                                                    seamless, high-quality solutions that exceed client expectations every time.
                                                </p>
                                            </div>
                                        </div>
                                        <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/about-us" className="theme-btn wow fadeInUp" data-wow-delay=".5s">More About Us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                {/* Service Section Start */}
                < section className="service-section fix section-padding" >
                    <div className="container">
                        <div className="section-title-area">
                            <div className="section-title">
                                <h6 className="wow fadeInUp">Our Services</h6>
                                <h2 className="wow fadeInUp" data-wow-delay=".3s">Our Spectrum of <span>Solutions</span></h2>
                            </div>
                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/services" className="theme-btn wow fadeInUp" data-wow-delay=".5s">See All Services</Link>
                        </div>
                        <div className="row g-0">
                            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".2s">
                                <div className="service-box-items">
                                    <div className="icon">
                                        <i className="fa-solid fa-terminal" />
                                    </div>
                                    <div className="content">
                                        <h3><Link to="/web-development">Web Development</Link></h3>
                                        <p>
                                            Creating responsive, user-friendly websites that deliver exceptional user experiences
                                            and drive business growth.
                                        </p>
                                        <div className="service-btn">
                                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/web-development" className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/web-development" className="link-btn">Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".4s">
                                <div className="service-box-items">
                                    <div className="icon">
                                        <i className="fa-solid fa-link" />
                                    </div>
                                    <div className="content">
                                        <h3><Link to="/blockchain-development">Blockchain Development</Link></h3>
                                        <p>
                                            Building secure, decentralized blockchain solutions tailored to your business needs,
                                            ensuring transparency and innovation.
                                        </p>
                                        <div className="service-btn">
                                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/blockchain-development" className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/blockchain-development" className="link-btn">Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".6s">
                                <div className="service-box-items">
                                    <div className="icon">
                                        <i className="fa-solid fa-palette" />
                                    </div>
                                    <div className="content">
                                        <h3><Link to="/graphics-design">Graphics Design</Link></h3>
                                        <p>
                                            Designing visually stunning graphics that capture your brand’s identity and engage your
                                            audience.
                                        </p>
                                        <div className="service-btn">
                                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/graphics-design" className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/graphics-design" className="link-btn">Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".8s">
                                <div className="service-box-items border-none">
                                    <div className="icon">
                                        <i className="fa-solid fa-bullhorn" />
                                    </div>
                                    <div className="content">
                                        <h3><Link to="/digital-marketing">Digital Marketing</Link></h3>
                                        <p>
                                            Strategizing and executing impactful digital marketing campaigns to boost your online
                                            presence and maximize reach.
                                        </p>
                                        <div className="service-btn">
                                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/digital-marketing" className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/digital-marketing" className="link-btn">Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                {/* Portfolio Section Start */}
                < section className="portfolio-section fix section-bg section-padding" >
                    <div className="container">
                        <div className="section-title-area">
                            <div className="section-title">
                                <h6 className="wow fadeInUp">Our Portfolio</h6>
                                <h2 className="wow fadeInUp" data-wow-delay=".3s">Some recent <span>work</span></h2>
                            </div>
                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/project" className="theme-btn wow fadeInUp" data-wow-delay=".5s">See All Works</Link>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-box-items wow fadeInUp" data-wow-delay=".3s">
                                    <div className="project-wrap">
                                        <div className="content">
                                            <span>Website, Branding</span>
                                            <h3><Link to="/project"> Florida Real Estate Web App<br /> In Florida</Link></h3>
                                        </div>
                                        <p>
                                            Created a user-friendly web app for Florida real estate, featuring property listings,
                                            advanced search options, and responsive design for seamless browsing on all devices.
                                        </p>
                                    </div>
                                    <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/project" className="radius-btn">
                                        <i className="fa-sharp fa-solid fa-arrow-right" />
                                        View Details
                                    </Link>
                                    <div className="project-hover d-none d-md-block bg-cover" style={{ backgroundImage: 'url("/assets/img/logo/logo.png")' }}>
                                    </div>
                                </div>
                                <div className="portfolio-box-items wow fadeInUp" data-wow-delay=".3s">
                                    <div className="project-wrap">
                                        <div className="content">
                                            <span>Blockchain, Backend Development</span>
                                            <h3><Link to="/project">Crypto Staking Platform</Link></h3>
                                        </div>
                                        <p>
                                            Built a secure platform for users to stake cryptocurrencies, featuring real-time rewards
                                            tracking and an intuitive interface.</p>
                                        {/* https://www.coinsquare.com/ */}
                                    </div>
                                    <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/project" className="radius-btn">
                                        <i className="fa-sharp fa-solid fa-arrow-right" />
                                        View Details
                                    </Link>
                                    <div className="project-hover d-none d-md-block bg-cover" style={{ backgroundImage: 'url("/assets/img/logo/logo.png")' }}>
                                    </div>
                                </div>
                                <div className="portfolio-box-items wow fadeInUp" data-wow-delay=".3s">
                                    <div className="project-wrap">
                                        <div className="content">
                                            <span>Digital Marketing, Graphics</span>
                                            <h3><Link to="/project">Building Brands from <br /> the Ground Up</Link></h3>
                                        </div>
                                        <p>
                                            Provided premium car detailing services in St. Pete, enhancing vehicle aesthetics and
                                            protecting investments for a flawless finish.
                                        </p>
                                    </div>
                                    <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/project" className="radius-btn">
                                        <i className="fa-sharp fa-solid fa-arrow-right" />
                                        View Details
                                    </Link>
                                    <div className="project-hover d-none d-md-block bg-cover" style={{ backgroundImage: 'url("/assets/img/logo/logo.png")' }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                {/* Testimonial Section Start */}
                < section className="testimonial-section fix section-bg section-padding" >
                    <div className="container">
                        <div className="section-title text-center">
                            <h6 className="wow fadeInUp">Testimonial</h6>
                            <h2 className="wow fadeInUp" data-wow-delay=".3s">Client <span>Feedback</span></h2>
                        </div>
                        <div className="swiper testimonial-slider">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="testimonial-box-items">
                                        <div className="client-info">
                                            <div className="team-img">
                                                <img src="/assets/img/03.webp" alt="img" />
                                            </div>
                                            <div className="content">
                                                <h5>Peter</h5>
                                                <span>Coinlabs Technologies</span>
                                            </div>
                                        </div>
                                        <p>
                                            DevUnicornLabs did an amazing job on my real estate website. The team was responsive,
                                            delivered on time, and exceeded my expectations. Highly recommended! "
                                        </p>
                                        <div className="testi-bottom">
                                            <div className="star">
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star-half-stroke" />
                                            </div>
                                            <div className="quote-icon">
                                                <i className="fa-solid fa-quote-right" style={{ color: '#108a00', fontSize: '55px' }} />
                                            </div>
                                        </div>
                                        <div className="icon">
                                            <img src="/assets/img/icon/icon.webp" alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-box-items">
                                        <div className="client-info">
                                            <div className="team-img">
                                                <img src="/assets/img/06.webp" alt="img" />
                                            </div>
                                            <div className="content">
                                                <h5>Juan David</h5>
                                                <span>Technology Manager</span>
                                            </div>
                                        </div>
                                        <p>
                                            Super happy with the website DevUnicornLabs built for me. Great communication, fast
                                            delivery, and exactly what I needed for my business! "
                                        </p>
                                        <div className="testi-bottom">
                                            <div className="star">
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star-half-stroke" />
                                            </div>
                                            <div className="quote-icon">
                                                <i className="fa-solid fa-quote-right" style={{ color: '#108a00', fontSize: '55px' }} />
                                            </div>
                                        </div>
                                        <div className="icon">
                                            <img src="/assets/img/icon/icon.webp" alt="img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >

        </>
    )
}

export default Home
import React from 'react'
import { Link } from 'react-router-dom'
import { scrollTop } from '../utils/functions';
const Header = ({ isOpen, setIsOpen }) => {

  const toggleSidebar = () => {
    // console.log("working a")
    setIsOpen(!isOpen);
  };


  return (


    <header id="header-sticky" className="header-1">
      <div className="container">
        <div className="mega-menu-wrapper">
          <div className="header-main">
            <div className="logo">
              <Link onClick={e => scrollTop()} to="/" className="header-logo">
                <img src="/assets/img/logo/logo-white.png" alt="logo-img" />
              </Link>
            </div>
            <div className="mean__menu-wrapper">
              <div className="main-menu">
                <nav id="mobile-menu">
                  <ul>
                    <li className="active menu-thumb">
                      <Link onClick={e => scrollTop()} to="/">
                        Home
                      </Link>
                    </li>
                    <li className="active d-xl-none">
                      <Link onClick={e => scrollTop()} to="/" className="border-none">
                        Home
                      </Link>
                    </li>

                    <li>
                      <Link onClick={e => scrollTop()} to="/services">
                        Services
                      </Link>
                    </li>
                    <li>
                      <Link onClick={e => scrollTop()} to="/project">
                        Projects
                      </Link>
                    </li>

                    <li>
                      <Link onClick={e => scrollTop()} to="about-us">About Us</Link>
                    </li>

                    <li>
                      <Link onClick={e => scrollTop()} to="/privacy-policy">
                        Privacy & Policy
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="header-right d-flex justify-content-end align-items-center">
              <div className="header-button">
                <Link onClick={e => scrollTop()} to="contact" className="theme-btn">Contact Us</Link>
              </div>
              <div className="header__hamburger d-xl-block my-auto">
                <div className="sidebar__toggle" onClick={e => toggleSidebar()}>
                  <i className="fas fa-bars" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
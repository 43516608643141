import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import './PrivacyPolicyPopup.css';

const PrivacyPolicyPopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHiding, setIsHiding] = useState(false);

  useEffect(() => {
    // Check if the user has already accepted the privacy policy
    const accepted = localStorage.getItem('privacyPolicyAccepted');
    if (!accepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('privacyPolicyAccepted', 'true');
    triggerHideAnimation();
  };

  const handleClose = () => {
    triggerHideAnimation();
  };

  const triggerHideAnimation = () => {
    setIsHiding(true);
    setTimeout(() => {
      setIsVisible(false);
      setIsHiding(false); // Reset for future visibility
    }, 500); // Match the duration of the animation
  };

  if (!isVisible) return null;

  return (
    <div className={`privacy-popup ${isHiding ? 'hide' : ''} row`}>
      <div className="privacy-popup-content col-md-6 offset-md-3">
        <h3>Please Review Our Privacy Policy</h3>
        <p>
          We store cookies on your device to enhance site navigation, analyze site usage, and assist in
          our marketing efforts. For more information on the information we collect, see our privacy
          policy{' '}
          <Link to="/privacy-policy" rel="noopener noreferrer">
            here
          </Link>
          . For details on our refund process, please refer to our{' '}
          <Link to="/refund-policy" rel="noopener noreferrer">
            Refund Policy
          </Link>.
        </p>
        <div className="privacy-popup-actions">
          <button className="accept-button" onClick={handleAccept}>
            Accept
          </button>
          <button className="close-button" onClick={handleClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPopup;

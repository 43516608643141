import React from 'react'
import { MdDomain } from "react-icons/md";
import { FaGoogle } from "react-icons/fa";
import { IoAnalytics } from "react-icons/io5";
import { FaPaintbrush } from "react-icons/fa6";
import { FaServer } from "react-icons/fa6";
import { RiSeoLine } from "react-icons/ri";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import {Link} from 'react-router-dom'


const Offer = () => {
  return (
    <div className='features'>
      <div className="brand-section section-padding fix pt-5  " bis_skin_checked={1}>
        <div className="container" bis_skin_checked={1}>
          <div className="brand-text" bis_skin_checked={1}>
            <h5> Enjoy Up to 40% Off on  <Link to="/services" style={{textDecoration:'underline'}}>Select Packages</Link>  – Offer Valid Until 31st January!  </h5>
          </div>
          <h2>Unlock Exclusive Bonuses for Worth $1000/- to Boost Your Website's Impact</h2>
          <p>As a bonus, we're offering the following with your website design package:</p>
          <div className="container">
            <div class="services-section">
              <div class="container">
                <div class="row">

                  <div class="col-md-6 col-sm-12 service-item">
                    <div class="service-content">
                      <div class="service-icon">
                        <MdDomain />
                      </div>
                      <div class="service-details">
                        <h3>Free Domain & Hosting</h3>
                        <p>Our offer includes a complimentary domain name. This ensures your website gets a professional online presence without any additional upfront cost for the first year.<span className='brand_color'>(worth $200/-)</span> </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12 service-item">
                    <div class="service-content">
                      <div class="service-icon">
                        < FaServer />
                      </div>
                      <div class="service-details">
                        <h3>Free Pop-up Design</h3>
                        <p>
                        Create a custom pop-up to promote special offers, collect email addresses, or announce important updates  <br/>
                        <span className='brand_color'>(worth $100/-)</span>.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 service-item">
                    <div class="service-content">
                      <div class="service-icon">
                        <FaPaintbrush />
                      </div>
                      <div class="service-details">
                        <h3>Free Logo Design</h3>
                        <p>
                          Get a visually striking logo that complements your website and brand identity <span className='brand_color'>(worth $100/-)</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12 service-item">
                    <div class="service-content">
                      <div class="service-icon">
                        <IoAnalytics />
                      </div>
                      <div class="service-details">
                        <h3>Free Google Analytics Setup</h3>
                        <p>
                          Track your website traffic, user behavior & conversions with a free Google Analytics account setup <span className='brand_color'>(worth $100/-)</span>  </p>
                      </div>
                    </div>
                  </div>


                  <div class="col-md-6 col-sm-12 service-item">
                    <div class="service-content">
                      <div class="service-icon">
                        <RiSeoLine />
                      </div>
                      <div class="service-details">
                        <h3>Free Basic SEO Optimization</h3>
                        <p>
                          We'll optimize your website's title tags, meta descriptions, and basic on-page elements to improve search engine ranking  <span className='brand_color'>(worth $300/-)</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12 service-item">
                    <div class="service-content">
                      <div class="service-icon">
                        <VscWorkspaceTrusted />
                      </div>
                      <div class="service-details">
                        <h3>Free Brand Style Guide Development</h3>
                        <p>
                          Establish a consistent brand voice and visual identity with a basic brand style guide <span className='brand_color'>(worth $200/-)</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Offer
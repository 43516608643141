import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const OffCanvas = ({ isOpen, setIsOpen }) => {
    const closeSidebar = () => {
        // console.log("working b")
        setIsOpen(false);
    };

    return (
        <>

            <div className="fix-area">
                <div className={`offcanvas__info ${isOpen ? 'info-open' : ''}`}>
                    <div className="offcanvas__wrapper">
                        <div className="offcanvas__content">
                            <div className="offcanvas__top mb-5 d-flex justify-content-between align-items-center">
                                <div className="offcanvas__logo">
                                    <Link
                                        to="index.html">
                                        <img src="/assets/img/logo/logo-white.png" alt="logo-img" />
                                    </Link>
                                </div>
                                <div className="offcanvas__close">
                                    <button onClick={closeSidebar}>
                                        <i className="fas fa-times" />
                                    </button>
                                </div>
                            </div>
                            <p className="text d-none d-xl-block">
                                Let’s bring your vision to life together! Reach out to start building something amazing.
                            </p>
                            <div className="mobile-menu fix mb-3" />
                            <div className="offcanvas__contact">
                                <h4>Contact Info</h4>
                                <ul>
                                    <li className="d-flex align-items-center">
                                        <div className="offcanvas__contact-icon">
                                            <i className="fa-solid fa-location-dot" />
                                        </div>
                                        <div className="offcanvas__contact-text">
                                            <Link
                                                target="_blank" to="#"> Block : A, Road : 08, Mirpur - Dhaka, Bangladesh</Link>
                                        </div>
                                    </li>
                                    {/* <li className="d-flex align-items-center">
                                        <div className="offcanvas__contact-icon mr-15">
                                            <i className="fa-regular fa-envelope-open" />
                                        </div>
                                        <div className="offcanvas__contact-text">
                                            <Link
                                                to="mailto:softdev.alamin@gmail.com"><span className="mailto:softdev.alamin@gmail.com">softdev.alamin@gmail.com</span></Link>
                                        </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                        <div className="offcanvas__contact-icon mr-15">
                                            <i className="fa-regular fa-clock" />
                                        </div>
                                        <div className="offcanvas__contact-text">
                                            <Link
                                                target="_blank" to="#"> [24/7] Always Here for You </Link>
                                        </div>
                                    </li>
                                    {/* <li className="d-flex align-items-center">
                                        <div className="offcanvas__contact-icon mr-15">
                                            <i className="fa-solid fa-phone" />
                                        </div>
                                        <div className="offcanvas__contact-text">
                                            <Link
                                                to="tel:+8801614643814">+8801614643814</Link>
                                        </div>
                                    </li> */}
                                </ul>
                                <div className="header-button mt-4">
                                    <Link
                                        to="/contact" className="theme-btn text-center">
                                        Get A Quote
                                    </Link>
                                </div>
                                <div className="social-icon d-flex align-items-center">
                                    <Link to="https://www.upwork.com/freelancers/~01e8847c146c768034"><i class="fa-brands fa-upwork"></i></Link> 
                                    <Link to="https://www.linkedin.com/company/devunicornlabs"><i className="fab fa-linkedin-in" /></Link>
                                    <Link target='_blank' to="https://github.com/Full-stack-Web-Dev-BD"><i className="fab  fa-github" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`offcanvas__overlay ${isOpen ? 'overlay-open' : ''}`} onClick={closeSidebar}></div>
        </>
    )
}

export default OffCanvas
import React from 'react'
import Clients from '../Components/Clients'
import { Link } from 'react-router-dom'
import { scrollTop } from '../utils/functions'

const About = () => {
  const reviews = [
    "Working with DevAssistPro transformed my real estate business. They created a stunning website that looks great and works seamlessly. Their professional team understood the real estate market perfectly. I'm very satisfied!",

    "DevAssistPro provided exceptional fintech solutions that greatly improved our operations. Their comprehensive platform enhanced user experience, making everything simpler. Their innovative approach truly made a difference. Highly recommend!",

    "I hired DevAssistPro for my e-commerce site, and the results are fantastic! The team was efficient and attentive, ensuring every need was met. Sales have significantly increased since launch, all thanks to their expertise!",

    "The marketing strategy from DevAssistPro has greatly impacted my business. They understood my brand , creating a campaign that resonates. Their insights have led to increased engagement and growth!"
  ];

  return (
    <>
      <div>
        {/* Breadcrumb Section Start */}
        <div className="breadcrumb-wrapper section-bg bg-cover" style={{ backgroundImage: 'url("assets/img/breadcrumb-shape.png")' }} bis_skin_checked={1}>
          <div className="arrow-shape" bis_skin_checked={1}>
            <img src="/assets/img/icon/arrow-shape.webp" alt="img" />
          </div>
          <div className="circle-shape" bis_skin_checked={1}>
            <img src="/assets/img/icon/circle-shape.png" alt="img" />
          </div>
          <div className="container" bis_skin_checked={1}>
            <div className="page-heading" bis_skin_checked={1}>
              <div className="breadcrumb-sub-title" bis_skin_checked={1}>
                <h1 className="wow fadeInUp" data-wow-delay=".3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>ABOUT US</h1>
              </div>
              <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s" style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp' }}>
                <li>
                  <Link href="/index">
                    Home
                  </Link>
                </li>
                <li>
                  <i className="fa-solid fa-angles-right" />
                </li>
                <li>
                  About Us
                </li>
              </ul>
            </div>
          </div>
          <div className="marquee-section fix" bis_skin_checked={1}>
            <div className="mycustom-marque" bis_skin_checked={1}>
              <div className="scrolling-wrap" bis_skin_checked={1}>
                <div className="comm" bis_skin_checked={1}>
                  <div className="cmn-textslide textitalick text-custom-storke" bis_skin_checked={1}>ABOUT US</div>
                  <div className="cmn-textslide textitalick" bis_skin_checked={1}>ABOUT US </div>
                  <div className="cmn-textslide textitalick text-custom-storke" bis_skin_checked={1}>ABOUT US </div>
                </div>
                <div className="comm" bis_skin_checked={1}>
                  <div className="cmn-textslide textitalick text-custom-storke" bis_skin_checked={1}>ABOUT US</div>
                  <div className="cmn-textslide textitalick" bis_skin_checked={1}>ABOUT US </div>
                  <div className="cmn-textslide textitalick text-custom-storke" bis_skin_checked={1}>ABOUT US </div>
                </div>
                <div className="comm" bis_skin_checked={1}>
                  <div className="cmn-textslide textitalick text-custom-storke" bis_skin_checked={1}>ABOUT US</div>
                  <div className="cmn-textslide textitalick" bis_skin_checked={1}>ABOUT US </div>
                  <div className="cmn-textslide textitalick text-custom-storke" bis_skin_checked={1}>ABOUT US </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About Section Start */}
        <section className="about-section fix section-padding">
          <div className="container" bis_skin_checked={1}>
            <div className="section-title-area" bis_skin_checked={1}>
              <div className="section-title" bis_skin_checked={1}>
                <h6 className="wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>About Us</h6>
                <h2 className="wow fadeInUp" data-wow-delay=".3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>Crafting Creative <span> Solutions for </span> <br /> Modern Brands
                </h2>
              </div>
              <h2 className="wow fadeInUp" data-wow-delay=".5s" style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp' }}>
                Our Mission, Vision, and <br /> Unique Value Proposition
              </h2>
            </div>
            <div className="about-wrapper" bis_skin_checked={1}>
              <div className="row justify-content-between align-items-center" bis_skin_checked={1}>
                <div className="col-lg-7" bis_skin_checked={1}>
                  <div className="about-image-items" bis_skin_checked={1}>
                    <div className="row g-4 align-items-center" bis_skin_checked={1}>
                      <div className="col-md-6 wow fadeInUp" data-wow-delay=".3s" bis_skin_checked={1} style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>
                        <div className="support-content" bis_skin_checked={1}>
                          <h3>24/7 Support</h3>
                          <div className="text-area" bis_skin_checked={1}>
                            <p>We provide 24/7 service <br /> to our customers</p>
                            <Link href="/about-us" className="icon">
                              <i className="fa-sharp fa-solid fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                        <div className="about-image" bis_skin_checked={1}>
                          <img src="/assets/img/01x.webp" alt="img" />
                        </div>
                      </div>
                      <div className="col-md-6 wow fadeInUp" data-wow-delay=".5s" bis_skin_checked={1} style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp' }}>
                        <div className="about-image-2" bis_skin_checked={1}>
                          <div className="about-image style-2" bis_skin_checked={1}>
                            <img src="/assets/img/02x.webp" alt="img" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5" bis_skin_checked={1}>
                  <div className="about-content" bis_skin_checked={1}>
                    <div className="about-icon-items wow fadeInUp" data-wow-delay=".3s" bis_skin_checked={1} style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>
                      <div className="icon" bis_skin_checked={1}>
                        <i className="fa-solid fa-dollar-sign" />
                        <div className="line-bar" bis_skin_checked={1}>
                          <img src="/assets/img/icon/line.webp" alt="img" />
                        </div>
                      </div>
                      <div className="content" bis_skin_checked={1}>
                        <h3>Our Mission</h3>
                        <p>Our mission is to empower businesses through innovative digital solutions that enhance their growth and success. We are dedicated to creating impactful strategies that connect with audiences and elevate brands.
                        </p>
                      </div>
                    </div>
                    <div className="about-icon-items wow fadeInUp" data-wow-delay=".5s" bis_skin_checked={1} style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp' }}>
                      <div className="icon" bis_skin_checked={1}>
                        <i className="fa-regular fa-star" />
                        <div className="line-bar" bis_skin_checked={1}>
                          <img src="/assets/img/icon/line.webp" alt="img" />
                        </div>
                      </div>
                      <div className="content" bis_skin_checked={1}>
                        <h3>Our Vision</h3>
                        <p>
                          Our vision is to be a leading agency recognized for transforming ideas into meaningful realities. We strive to foster creativity and collaboration, helping businesses thrive in an ever-evolving digital landscape.
                        </p>
                      </div>
                    </div>
                    <div className="about-icon-items wow fadeInUp" data-wow-delay=".3s" bis_skin_checked={1} style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>
                      <div className="icon" bis_skin_checked={1}>
                        <i className="fa-solid fa-shield-halved" />
                        <div className="line-bar" bis_skin_checked={1}>
                          <img src="/assets/img/icon/line.webp" alt="img" />
                        </div>
                      </div>
                      <div className="content" bis_skin_checked={1}>
                        <h3>What Sets Us Apart</h3>
                        <p>
                          What sets us apart is our commitment to understanding each client's unique needs. By combining industry expertise with a passion for innovation, we deliver tailored solutions that drive results and exceed expectations.
                        </p>
                      </div>
                    </div>
                    <Link onClick={e => scrollTop()} href="/about-us" className="theme-btn wow fadeInUp" data-wow-delay=".5s" style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp' }}>More About
                      Us</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Counter Section Start */}
        <section className="counter-section section-bg section-padding">
          <div className="container" bis_skin_checked={1}>
            <div className="row" bis_skin_checked={1}>
              <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".2s" bis_skin_checked={1} style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp' }}>
                <div className="counter-items style-2" bis_skin_checked={1}>
                  <h2><span className="count">6</span>+</h2>
                  <p>YEAR EXPERIENCE</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".4s" bis_skin_checked={1} style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>
                <div className="counter-items" bis_skin_checked={1}>
                  <h2><span className="count">60</span>+</h2>
                  <p>HAPPY CUSTOMER</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".6s" bis_skin_checked={1} style={{ visibility: 'visible', animationDelay: '0.6s', animationName: 'fadeInUp' }}>
                <div className="counter-items style-2" bis_skin_checked={1}>
                  <h2><span className="count">4.9</span></h2>
                  <p>Rating</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".8s" bis_skin_checked={1} style={{ visibility: 'visible', animationDelay: '0.8s', animationName: 'fadeInUp' }}>
                <div className="counter-items" bis_skin_checked={1}>
                  <h2><span className="count">98%</span></h2>
                  <p >SATISFACTION RATE</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Service Section Start */}

        <section className="service-section fix section-padding">
          <div className="container">
            <div className="section-title-area">
              <div className="section-title">
                <h6 className="wow fadeInUp">Our Services</h6>
                <h2 className="wow fadeInUp" data-wow-delay=".3s">Our Spectrum of <span>Solutions</span></h2>
              </div>
              <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/services" className="theme-btn wow fadeInUp" data-wow-delay=".5s">See All Services</Link>
            </div>
            <div className="row g-0">
              <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".2s">
                <div className="service-box-items">
                  <div className="icon">
                    <i className="fa-solid fa-terminal" />
                  </div>
                  <div className="content">
                    <h3><Link to="/web-development">Web Development</Link></h3>
                    <p>
                      Creating responsive, user-friendly websites that deliver exceptional user experiences
                      and drive business growth.
                    </p>
                    <div className="service-btn">
                      <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/web-development" className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                      <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/web-development" className="link-btn">Read more</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".4s">
                <div className="service-box-items">
                  <div className="icon">
                    <i className="fa-solid fa-link" />
                  </div>
                  <div className="content">
                    <h3><Link to="/blockchain-development">Blockchain Development</Link></h3>
                    <p>
                      Building secure, decentralized blockchain solutions tailored to your business needs,
                      ensuring transparency and innovation.
                    </p>
                    <div className="service-btn">
                      <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/blockchain-development" className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                      <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/blockchain-development" className="link-btn">Read more</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".6s">
                <div className="service-box-items">
                  <div className="icon">
                    <i className="fa-solid fa-palette" />
                  </div>
                  <div className="content">
                    <h3><Link to="/graphics-design">Graphics Design</Link></h3>
                    <p>
                      Designing visually stunning graphics that capture your brand’s identity and engage your
                      audience.
                    </p>
                    <div className="service-btn">
                      <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/graphics-design" className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                      <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/graphics-design" className="link-btn">Read more</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".8s">
                <div className="service-box-items border-none">
                  <div className="icon">
                    <i className="fa-solid fa-bullhorn" />
                  </div>
                  <div className="content">
                    <h3><Link to="/digital-marketing">Digital Marketing</Link></h3>
                    <p>
                      Strategizing and executing impactful digital marketing campaigns to boost your online
                      presence and maximize reach.
                    </p>
                    <div className="service-btn">
                      <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/digital-marketing" className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                      <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/digital-marketing" className="link-btn">Read more</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Portfolio Section Start */}

        <section className="portfolio-section fix section-bg section-padding">
          <div className="container">
            <div className="section-title-area">
              <div className="section-title">
                <h6 className="wow fadeInUp">Our Portfolio</h6>
                <h2 className="wow fadeInUp" data-wow-delay=".3s">Some recent <span>work</span></h2>
              </div>
              <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/project" className="theme-btn wow fadeInUp" data-wow-delay=".5s">See All Works</Link>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-box-items wow fadeInUp" data-wow-delay=".3s">
                  <div className="project-wrap">
                    <div className="content">
                      <span>Website, Branding</span>
                      <h3><Link to="/project"> Florida Real Estate Web App<br /> In Florida</Link></h3>
                    </div>
                    <p>
                      Created a user-friendly web app for Florida real estate, featuring property listings,
                      advanced search options, and responsive design for seamless browsing on all devices.
                    </p>
                  </div>
                  <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/project" className="radius-btn">
                    <i className="fa-sharp fa-solid fa-arrow-right" />
                    View Details
                  </Link>
                  <div className="project-hover d-none d-md-block bg-cover" style={{ backgroundImage: 'url("/assets/img/logo/logo.png")' }}>
                  </div>
                </div>
                <div className="portfolio-box-items wow fadeInUp" data-wow-delay=".3s">
                  <div className="project-wrap">
                    <div className="content">
                      <span>Blockchain, Backend Development</span>
                      <h3><Link to="/project">Crypto Staking Platform</Link></h3>
                    </div>
                    <p>
                      Built a secure platform for users to stake cryptocurrencies, featuring real-time rewards
                      tracking and an intuitive interface.</p>
                    {/* https://www.coinsquare.com/ */}
                  </div>
                  <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/project" className="radius-btn">
                    <i className="fa-sharp fa-solid fa-arrow-right" />
                    View Details
                  </Link>
                  <div className="project-hover d-none d-md-block bg-cover" style={{ backgroundImage: 'url("/assets/img/logo/logo.png")' }}>
                  </div>
                </div>v
                <div className="portfolio-box-items wow fadeInUp" data-wow-delay=".3s">
                  <div className="project-wrap">
                    <div className="content">
                      <span>Digital Marketing, Graphics</span>
                      <h3><Link to="/project">Building Brands from <br /> the Ground Up</Link></h3>
                    </div>
                    <p>
                      Provided premium car detailing services in St. Pete, enhancing vehicle aesthetics and
                      protecting investments for a flawless finish.
                    </p>
                  </div>
                  <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/project" className="radius-btn">
                    <i className="fa-sharp fa-solid fa-arrow-right" />
                    View Details
                  </Link>
                  <div className="project-hover d-none d-md-block bg-cover" style={{ backgroundImage: 'url("/assets/img/logo/logo.png")' }}>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Team Section Start */} 
        <section className="testimonial-section fix section-padding section-bg">
          <div className="container" bis_skin_checked={1}>
            <div className="tesimonial-wrapper-2" bis_skin_checked={1}>



              <div className="row g-4" bis_skin_checked={1}>
                <div className="col-lg-5" bis_skin_checked={1}>
                  <div className="testimonial-image" bis_skin_checked={1}>
                    <img src="/assets/img/testimonial.webp" alt="img" />
                  </div>
                </div>
                <div className="col-lg-5" bis_skin_checked={1}>
                  <div className="testimonial-content" bis_skin_checked={1}>
                    <div className="section-title" bis_skin_checked={1}>
                      <h6 className="wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                        Testimonial</h6>
                      <h2 className="wow fadeInUp" data-wow-delay=".3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>Client
                        <span>Feedback</span>
                      </h2>
                    </div>
                    <p className="mt-3 mt-md-0 wow fadeInUp" data-wow-delay=".5s" style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp' }}>
                      From concept to execution, DevUnicornLabs nailed every aspect of our campaign. Their expertise
                      and creativity have taken our brand to the next level.
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper testimonial-slider-2 swiper-initialized swiper-horizontal swiper-pointer-events" bis_skin_checked={1}>
                <div className="swiper-wrapper" id="swiper-wrapper-7a1310a4cd8cc34105" aria-live="off" bis_skin_checked={1} style={{ transitionDuration: '2000ms', transform: 'translate3d(-3622px, 0px, 0px)' }}>
                  <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index={0} role="group" aria-label="1 / 4" bis_skin_checked={1} style={{ width: '422.75px', marginRight: '30px' }}>
                    <div className="testimonial-card-items" bis_skin_checked={1}>
                      <div className="testi-top" bis_skin_checked={1}>
                        <div className="star" bis_skin_checked={1}>
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star-half-stroke" />
                        </div>
                        <div className="quote-icon" bis_skin_checked={1}>
                          <i className="fa-solid fa-quote-right" style={{ color: '#108a00', fontSize: '55px' }} />
                        </div>
                      </div>
                      <p> {reviews[0]} </p>
                      <div className="client-info" bis_skin_checked={1}>
                        <div className="team-img" bis_skin_checked={1}>
                          <img src="/assets/img/03.webp" alt="img" />
                        </div>
                        <div className="content" bis_skin_checked={1}>
                          <h5>Peter </h5>
                          <span>Coinlabs Technologies </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index={1} role="group" aria-label="2 / 4" bis_skin_checked={1} style={{ width: '422.75px', marginRight: '30px' }}>
                    <div className="testimonial-card-items" bis_skin_checked={1}>
                      <div className="testi-top" bis_skin_checked={1}>
                        <div className="star" bis_skin_checked={1}>
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star-half-stroke" />
                        </div>
                        <div className="quote-icon" bis_skin_checked={1}>
                          <i className="fa-solid fa-quote-right" style={{ color: '#108a00', fontSize: '55px' }} />
                        </div>
                      </div>
                      <p> {reviews[1]} </p>
                      <div className="client-info" bis_skin_checked={1}>
                        <div className="team-img" bis_skin_checked={1}>
                          <img src="/assets/img/04.webp" alt="img" />
                        </div>
                        <div className="content" bis_skin_checked={1}>
                          <h5>Nathan Lamphere </h5>
                          <span>Third Eye Technologies</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index={2} role="group" aria-label="3 / 4" bis_skin_checked={1} style={{ width: '422.75px', marginRight: '30px' }}>
                    <div className="testimonial-card-items" bis_skin_checked={1}>
                      <div className="testi-top" bis_skin_checked={1}>
                        <div className="star" bis_skin_checked={1}>
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star-half-stroke" />
                        </div>
                        <div className="quote-icon" bis_skin_checked={1}>
                          <i className="fa-solid fa-quote-right" style={{ color: '#108a00', fontSize: '55px' }} />
                        </div>
                      </div>
                      <p> {reviews[2]} </p>
                      <div className="client-info" bis_skin_checked={1}>
                        <div className="team-img" bis_skin_checked={1}>
                          <img src="/assets/img/05.webp" alt="img" />
                        </div>
                        <div className="content" bis_skin_checked={1}>
                          <h5>Juan David</h5>
                          <span> Technology Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index={3} role="group" aria-label="4 / 4" bis_skin_checked={1} style={{ width: '422.75px', marginRight: '30px' }}>
                    <div className="testimonial-card-items" bis_skin_checked={1}>
                      <div className="testi-top" bis_skin_checked={1}>
                        <div className="star" bis_skin_checked={1}>
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star-half-stroke" />
                        </div>
                        <div className="quote-icon" bis_skin_checked={1}>
                          <i className="fa-solid fa-quote-right" style={{ color: '#108a00', fontSize: '55px' }} />
                        </div>
                      </div>
                      <p> {reviews[3]} </p>
                      <div className="client-info" bis_skin_checked={1}>
                        <div className="team-img" bis_skin_checked={1}>
                          <img src="/assets/img/06.webp" alt="img" />
                        </div>
                        <div className="content" bis_skin_checked={1}>
                          <h5>Vladi Bocharov</h5>
                          <span>Clickbuy</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide swiper-slide-duplicate-active" data-swiper-slide-index={0} role="group" aria-label="1 / 4" bis_skin_checked={1} style={{ width: '422.75px', marginRight: '30px' }}>
                    <div className="testimonial-card-items" bis_skin_checked={1}>
                      <div className="testi-top" bis_skin_checked={1}>
                        <div className="star" bis_skin_checked={1}>
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star-half-stroke" />
                        </div>
                        <div className="quote-icon" bis_skin_checked={1}>
                          <i className="fa-solid fa-quote-right" style={{ color: '#108a00', fontSize: '55px' }} />
                        </div>
                      </div>
                      <p> {reviews[0]} </p>
                      <div className="client-info" bis_skin_checked={1}>
                        <div className="team-img" bis_skin_checked={1}>
                          <img src="/assets/img/03.webp" alt="img" />
                        </div>
                        <div className="content" bis_skin_checked={1}>
                          <h5>Peter </h5>
                          <span>Coinlabs</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide swiper-slide-duplicate-next" data-swiper-slide-index={1} role="group" aria-label="2 / 4" bis_skin_checked={1} style={{ width: '422.75px', marginRight: '30px' }}>
                    <div className="testimonial-card-items" bis_skin_checked={1}>
                      <div className="testi-top" bis_skin_checked={1}>
                        <div className="star" bis_skin_checked={1}>
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star-half-stroke" />
                        </div>
                        <div className="quote-icon" bis_skin_checked={1}>
                          <i className="fa-solid fa-quote-right" style={{ color: '#108a00', fontSize: '55px' }} />
                        </div>
                      </div>
                      <p> {reviews[1]} </p>
                      <div className="client-info" bis_skin_checked={1}>
                        <div className="team-img" bis_skin_checked={1}>
                          <img src="/assets/img/04.webp" alt="img" />
                        </div>
                        <div className="content" bis_skin_checked={1}>
                          <h5>Nathan Lamphere </h5>
                          <span>Third Eye Technologies</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide" data-swiper-slide-index={2} role="group" aria-label="3 / 4" bis_skin_checked={1} style={{ width: '422.75px', marginRight: '30px' }}>
                    <div className="testimonial-card-items" bis_skin_checked={1}>
                      <div className="testi-top" bis_skin_checked={1}>
                        <div className="star" bis_skin_checked={1}>
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star-half-stroke" />
                        </div>
                        <div className="quote-icon" bis_skin_checked={1}>
                          <i className="fa-solid fa-quote-right" style={{ color: '#108a00', fontSize: '55px' }} />
                        </div>
                      </div>
                      <p> {reviews[2]} </p>
                      <div className="client-info" bis_skin_checked={1}>
                        <div className="team-img" bis_skin_checked={1}>
                          <img src="/assets/img/05.webp" alt="img" />
                        </div>
                        <div className="content" bis_skin_checked={1}>
                          <h5>Juan David</h5>
                          <span> Technology Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide swiper-slide-prev" data-swiper-slide-index={3} role="group" aria-label="4 / 4" bis_skin_checked={1} style={{ width: '422.75px', marginRight: '30px' }}>
                    <div className="testimonial-card-items" bis_skin_checked={1}>
                      <div className="testi-top" bis_skin_checked={1}>
                        <div className="star" bis_skin_checked={1}>
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star-half-stroke" />
                        </div>
                        <div className="quote-icon" bis_skin_checked={1}>
                          <i className="fa-solid fa-quote-right" style={{ color: '#108a00', fontSize: '55px' }} />
                        </div>
                      </div>
                      <p> {reviews[3]} </p>
                      <div className="client-info" bis_skin_checked={1}>
                        <div className="team-img" bis_skin_checked={1}>
                          <img src="/assets/img/06.webp" alt="img" />
                        </div>
                        <div className="content" bis_skin_checked={1}>
                          <h5>Vladi Bocharov</h5>
                          <span>Clickbuy</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide swiper-slide-duplicate swiper-slide-active" data-swiper-slide-index={0} role="group" aria-label="1 / 4" bis_skin_checked={1} style={{ width: '422.75px', marginRight: '30px' }}>
                    <div className="testimonial-card-items" bis_skin_checked={1}>
                      <div className="testi-top" bis_skin_checked={1}>
                        <div className="star" bis_skin_checked={1}>
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star-half-stroke" />
                        </div>
                        <div className="quote-icon" bis_skin_checked={1}>
                          <i className="fa-solid fa-quote-right" style={{ color: '#108a00', fontSize: '55px' }} />
                        </div>
                      </div>
                      <p> {reviews[0]} </p>
                      <div className="client-info" bis_skin_checked={1}>
                        <div className="team-img" bis_skin_checked={1}>
                          <img src="/assets/img/03.webp" alt="img" />
                        </div>
                        <div className="content" bis_skin_checked={1}>
                          <h5>Peter </h5>
                          <span>Coinlabs</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide swiper-slide-duplicate swiper-slide-next" data-swiper-slide-index={1} role="group" aria-label="2 / 4" bis_skin_checked={1} style={{ width: '422.75px', marginRight: '30px' }}>
                    <div className="testimonial-card-items" bis_skin_checked={1}>
                      <div className="testi-top" bis_skin_checked={1}>
                        <div className="star" bis_skin_checked={1}>
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star-half-stroke" />
                        </div>
                        <div className="quote-icon" bis_skin_checked={1}>
                          <i className="fa-solid fa-quote-right" style={{ color: '#108a00', fontSize: '55px' }} />
                        </div>
                      </div>
                      <p> {reviews[1]} </p>
                      <div className="client-info" bis_skin_checked={1}>
                        <div className="team-img" bis_skin_checked={1}>
                          <img src="/assets/img/04.webp" alt="img" />
                        </div>
                        <div className="content" bis_skin_checked={1}>
                          <h5>Nathan Lamphere </h5>
                          <span>Third Eye Technologies</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index={2} role="group" aria-label="3 / 4" bis_skin_checked={1} style={{ width: '422.75px', marginRight: '30px' }}>
                    <div className="testimonial-card-items" bis_skin_checked={1}>
                      <div className="testi-top" bis_skin_checked={1}>
                        <div className="star" bis_skin_checked={1}>
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star" />
                          <i className="fa-solid fa-star-half-stroke" />
                        </div>
                        <div className="quote-icon" bis_skin_checked={1}>
                          <i className="fa-solid fa-quote-right" style={{ color: '#108a00', fontSize: '55px' }} />
                        </div>
                      </div>
                      <p> {reviews[2]} </p>
                      <div className="client-info" bis_skin_checked={1}>
                        <div className="team-img" bis_skin_checked={1}>
                          <img src="/assets/img/05.webp" alt="img" />
                        </div>
                        <div className="content" bis_skin_checked={1}>
                          <h5>Juan David</h5>
                          <span> Technology Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="swiper-dot pt-5" bis_skin_checked={1}>
                  <div className="dot swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal" bis_skin_checked={1}><span className="swiper-pagination-bullet swiper-pagination-bullet-active" tabIndex={0} role="button" aria-label="Go to slide 1" aria-current="true" /><span className="swiper-pagination-bullet" tabIndex={0} role="button" aria-label="Go to slide 2" /><span className="swiper-pagination-bullet" tabIndex={0} role="button" aria-label="Go to slide 3" /><span className="swiper-pagination-bullet" tabIndex={0} role="button" aria-label="Go to slide 4" /></div>
                </div> */}
                <span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
              </div>
            </div>
          </div>
        </section>
        {/* Brand Section Start */}
        <Clients />
      </div>


    </>
  )
}

export default About
import React from 'react'
import { Link } from 'react-router-dom'


import "./privacy_policy.css"
const PrivacyAndPolicy = () => {
    return (
        <div className=''>
            <div>
                {/* Breadcrumb Section Start */}
                <div className="breadcrumb-wrapper section-bg bg-cover" style={{ backgroundImage: 'url("/assets/img/icon/breadcrumb-shape.webp")' }}>
                    <div className="arrow-shape">
                        <img src="/assets/img/icon/arrow-shape.webp" alt="img" />
                    </div>
                    <div className="circle-shape">
                        <img src="/assets/img/icon/circle-shape.webp" alt="img" />
                    </div>
                    <div className="container">
                        <div className="page-heading">
                            <div className="breadcrumb-sub-title">
                                <h1 className="wow fadeInUp" data-wow-delay=".3s">Privacy & Policy</h1>
                            </div>
                            <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                                <li>
                                    <a href="index.html">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <i className="fa-solid fa-angles-right" />
                                </li>
                                <li>
                                    Privacy & Policy
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="marquee-section fix">
                        <div className="mycustom-marque">
                            <div className="scrolling-wrap">
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Privacy & Policy</div>
                                    <div className="cmn-textslide textitalick">Privacy & Policy</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Privacy & Policy</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Privacy & Policy</div>
                                    <div className="cmn-textslide textitalick">Privacy & Policy</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Privacy & Policy</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Privacy & Policy</div>
                                    <div className="cmn-textslide textitalick">Privacy & Policy</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Privacy & Policy</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 404 Error Section Start */}
                <section className="error-section section-padding fix privacy_policy">
                    <div className="container">
                        <section class="container container--100">
                            <div class="container__col container__col--100" bis_skin_checked="1">
                                <section class="page-header page-header--left">

                                    <div class="page-header__content" bis_skin_checked="1">
                                        <h2 class="page-header__title">DevUnicornLabs Privacy Policy</h2>
                                    </div>

                                </section>

                                <section class="rich-text js-rich-text component ">
                                    <p>By making a payment for our services, you agree to our Refund Policy and acknowledge that your personal data will be handled in accordance with our <Link className='link_text' to="/refund-policy"> Refund Policy</Link>.</p>

                                    <p>DevUnicornLabs is a web development agency that creates and supports digital experiences for our clients. We
                                        respect the privacy of every individual who uses this website. This Privacy Policy outlines the
                                        information we collect and how we will use that information. By using this website, you agree to the
                                        collection and use of information in accordance with our Privacy Policy. Please read this Privacy Policy
                                        carefully before entering any information about yourself. If you have any questions or concerns about
                                        our Privacy Policy or your personal data, please contact us at <a




                                            href="mailto:devunicornlabs@gmail.com">devunicornlabs@gmail.com</a>.</p>

                                    <h2 className='mt-5'>What Personal Data We Collect and How&nbsp;We&nbsp;Collect&nbsp;It  </h2>
                                    <p>DevUnicornLabs, may at times collect your personal data in one of the following ways. </p>
                                    <ul>
                                        <li><strong>Website Forms:</strong> If you enter information such as your name, and email address,
                                            country of residence, company you represent, industry, and details of an inquiry on our Contact page
                                            or on other forms throughout our website. This data enables us to respond to a general/business
                                            inquiry made by you or on behalf of the company that you represent. We may also collect your phone
                                            number, if you wish to be contacted by telephone. </li>
                                        <li><strong>Cookies and Web Beacons:</strong> We use cookies and web beacons to collect data on how you
                                            use our websites. For example, this may include information about what DevUnicornLabs websites you have
                                            visited, how long you stayed on them, which items you clicked on, and your IP address. </li>
                                        <li><strong>Career Applications:</strong> If you send in your resume and other personal details through
                                            our Careers page available at <a target='_blank' href="https://www.linkedin.com/company/105367658">https://www.linkedin.com/company/105367658</a>,
                                            which enables you to apply online for a job at DevUnicornLabs. We may use this information throughout DevUnicornLabs
                                            to consider you for employment or to respond to your inquiry. We may also keep the information for
                                            future consideration for any positions that may arise at DevUnicornLabs. </li>

                                        <li><strong>Communication Subscriptions:</strong> You may provide us with information when subscribing
                                            to our communications. For example, this data may include your name, email address, and the company
                                            you represent. We also keep track of your areas of interest (such as receiving newsletters, thought
                                            leadership content, and/or marketing collateral) that you may have either opted into or opted out
                                            of. </li>
                                        <li><strong>Email Communications:</strong> If you receive our marketing communications, we may track
                                            whenever you receive, open, click a link, or share an email you receive from us. To unsubscribe from
                                            DevUnicornLabs marketing communications, please click the unsubscribe link in our emails.  </li>
                                        <li><strong>Information from Other Sources:</strong> DevUnicornLabs might receive information about you from
                                            other sources, such as public sources, social media, directories, registries to ensure the accuracy
                                            and completeness of your personal data. </li>
                                    </ul>
                                    <h2 className='mt-5'>Purpose and Lawful Basis for Processing Personal&nbsp;Data </h2>
                                    <ul>
                                        <li>Where we require your consent, you will find opt-in mechanisms and the ability to withdraw your
                                            consent at any time. </li>
                                        <li>Where we need to perform a contract, we are about to enter into or have entered into with you. For
                                            example, when we perform our services for you or the company you represent. </li>
                                        <li>Where we need to comply with a legal or regulatory obligation. This will include maintaining
                                            records, compliance checks or screening and recording (e.g., anti-money laundering, financial and
                                            credit checks, fraud and crime prevention and detection, trade sanctions, and embargo laws). This
                                            may include automated checks of personal data you provide about your identity against relevant
                                            databases and contacting you to confirm your identity or making records of our communications with
                                            you for compliance purposes. </li>
                                        <li>Where it is necessary for our legitimate interests or those of a third party and your interests and
                                            fundamental rights do not override those interests. For example, our legitimate interest will
                                            include market research purposes, marketing purposes and appropriate controls to ensure our website,
                                            processes and procedures are running effectively for the prevention and detention of against fraud
                                            and for IT security purposes. You can obtain further information about how we assess our legitimate
                                            interests against any potential impact on you by contacting us. </li>
                                    </ul>
                                    <p>If processing of personal data is subject to any other laws, then the lawful basis of processing personal
                                        data may be different to that set out above and those circumstances will be based on national laws. </p>
                                    <p>DevUnicornLabs will only process the received information needed to pursue our legitimate business interests. For
                                        example, screening CVs of prospective candidates, establishing communication with prospective clients
                                        (whom you represent) and personnel with general/ business inquiries, and to enhance our user experience.
                                    </p>
                                    <p>You also have the option to subscribe/opt-in to receive newsletters, thought leadership content and/or
                                        marketing collateral. You can always opt-out of such options via the link in your newsletter emails or
                                        by writing to <a href="mailto:devunicornlabs@gmail.com">devunicornlabs@gmail.com</a>. </p>
                                    <h2 className='mt-5'>Security </h2>
                                    <p>DevUnicornLabs implements industry standard security measures to keep your personal data secure and confidential,
                                        including but not limited to: </p>
                                    <ul>
                                        <li>Restricting access to your personal data to DevUnicornLabs employees strictly on a need-to-know basis, such
                                            as responding to an inquiry or request.</li>
                                        <li>Implementing physical, electronic, administrative, technical, and procedural safeguards that comply
                                            with all applicable rules, laws, legislation, and regulations to protect your personal data from
                                            unauthorized or inappropriate access, alteration, disclosure and/or destruction. </li>
                                    </ul>
                                    <h2 className='mt-5'>Cookies and IP Data </h2>
                                    <p>We use “cookies” on this site. A cookie is a piece of data stored on your device to help us improve your
                                        access to our site and identify repeat visitors. Cookies can also enable us to track and target the
                                        interests of our users to enhance their experience on our site. We also collect information indirectly
                                        through our Internet access logs. When you visit DevUnicornLabs.com, your Internet Protocol (IP) address is
                                        automatically collected and is placed in our Internet access logs. IP addresses are not linked to
                                        personally identifiable information. Your IP address is used to help diagnose any technical problems,
                                        analyze site trends, administer the site, track movement within the site, and gather broad demographic
                                        information about site use that can eventually be used to make improvements that enhance your user
                                        experience on DevUnicornLabs.com. Except as otherwise specifically stated in this Privacy Policy, DevUnicornLabs does not
                                        sell, exchange, trade or give away personal information you voluntarily provide to us to any outside
                                        sources. </p>
                                    <p>By visiting DevUnicornLabs.com you agree that we can place these cookies on your device. Persistent cookies can be
                                        removed by following your web browser’s directions. A session cookie is temporary and disappears after
                                        you close your browser. You can reset your web browser to refuse all cookies or to indicate when a
                                        cookie is being sent. </p>
                                    <p>Web beacons, conversion pixels and other technologies for similar purposes may be included on these
                                        sites, in marketing emails or our newsletters to determine whether messages have been opened and links
                                        clicked on. Web beacons do not place information on your device but may work together with cookies to
                                        monitor website activity. The information provided in this section, also applies to this technology.
                                        “Conversion pixels” are small codes located on webpages which may be triggered when someone visits them,
                                        resulting in an increase in their conversion count. </p>
                                    <p>DevUnicornLabs may analyze your IP and browser information to determine what is most effective about our website,
                                        to help us identify ways to improve it and, eventually, to determine who we can make improvements to
                                        provide a better user experience. </p>
                                    <p>Further examples of the information we may gather with these cookies, and how it is used, is set forth in
                                        “<strong>What Personal Data We Collect and How We Collect It</strong>,” above. </p>
                                    <h2 className='mt-5'>Third Parties: Who We May Share Your Personal&nbsp;Data&nbsp;With</h2>
                                    <ul>
                                        <li>The personal data DevUnicornLabs collects from you is stored in one or more databases hosted by third
                                            parties. These third parties do not use or have access to your personal data for any purpose other
                                            than cloud storage and retrieval. </li>
                                        <li>Where required or permitted by law, information may be provided to others, such as but not limited
                                            to regulators, through legal processes, or enforceable government directives. </li>
                                        <li>From time to time, DevUnicornLabs may consider corporate transactions such as a merger, acquisition,
                                            reorganization, or asset sale. In these instances, we may transfer or allow access to information to
                                            enable the assessment and undertaking of that transaction. If we buy or sell any business or assets,
                                            personal data may be transferred to third parties involved in the transaction. In these instances,
                                            we will continue to ensure your personal data is protected and private, and we will give any
                                            impacted data subjects notice before any personal data is transferred or subject to a different
                                            privacy policy. </li>
                                    </ul>
                                    <h2 className='mt-5'>Data Storage and Retention </h2>
                                    <p>DevUnicornLabs will retain your personal data relevant to the business purposes. We will only keep your personal
                                        data for as long as is reasonably necessary, taking into consideration our purposes outlined above or to
                                        comply with legal, accounting or reporting requirements under applicable law(s). </p>
                                    <p>To determine the appropriate retention period for personal data, we consider the amount, nature, and
                                        sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of
                                        personal data, the purposes for which we process the personal data and whether we can achieve those
                                        purposes through other means, and the applicable legal requirements. </p>
                                    <p>Personal data received from prospective clients shall be retained for the duration of the prospective
                                        client’s business relationship with the DevUnicornLabs and in accordance with the retention criteria set out in
                                        our client privacy notice. </p>
                                    <p>For more information on where and how long your personal data is stored, and for more information on your
                                        rights of erasure and portability, please contact us at <a
                                            href="mailto:devunicornlabs@gmail.com">devunicornlabs@gmail.com</a>. </p>
                                    <h2 className='mt-5'>Existence of Automated Profiling </h2>
                                    <p>DevUnicornLabs uses automated profiling in limited circumstances related to email campaigns. As part of our email
                                        campaigns, we may track when you receive, open, click a link, or share an email you receive from us
                                        using a DevUnicornLabs managed automated solution. The automated solution profiles the information tracked to
                                        evaluate your interest in our service offerings and/or promotions. This enables us to identify and
                                        target potential customers and/or business partners and aims to provide you with relevant and timely
                                        content based on your indicated interests. </p>
                                    <h2 className='mt-5'>Data Subject Rights </h2>
                                    <p>You may have certain rights relating to your personal data provided under the GDPR. These include the
                                        right to: </p>
                                    <ul>
                                        <li>Request access to your personal data and request details of the processing activities conducted by
                                            DevUnicornLabs. </li>
                                        <li>Request that your personal data is rectified if it is inaccurate or incomplete. </li>
                                        <li>Request erasure of your personal data in certain circumstances. </li>
                                        <li>Request restriction of the processing of your personal data in certain circumstances. </li>
                                        <li>Object to the processing of your personal data in certain circumstances. </li>
                                        <li>Receive your personal data provided to us as a controller in a structured, commonly used, and
                                            machine-readable format in certain circumstances. </li>
                                        <li>Object to, and not to be subject to a decision based solely on, automated processing (including
                                            profiling), which produces legal effects or significantly affects you. </li>
                                        <li>Withdraw your consent provided at any time by contacting us. </li>
                                    </ul>
                                    <p>To exercise the rights outlined above in respect of your personal data you may write to us at <a
                                        href="mailto:devunicornlabs@gmail.com">devunicornlabs@gmail.com</a>. </p>
                                    <p><a href="/privacy-policy/ccpa">Click here to read the California Privacy Notice (CCPA)</a></p>
                                    <h2 className='mt-5'>Contact Us </h2>
                                    <p>If you have any questions or concerns as to how your data is being processed, you can contact us by
                                        writing to <a href="mailto:devunicornlabs@gmail.com">devunicornlabs@gmail.com</a>. </p>
                                    <h2 className='mt-5'>Complaints </h2>
                                    <p>Please contact us with concerns you may have. Data protection laws such as the GDPR give you the right to
                                        lodge a complaint with a supervisory authority, in the European Union (or European Economic Area) state
                                        where you work, normally live, or where any alleged infringement of data protection laws has occurred.
                                    </p>
                                    <h2 className='mt-5'>Changes to our Website Privacy Notice </h2>
                                    <p>This Website Privacy Notice was last updated in May 2024 and DevUnicornLabs will notify you of changes we may make
                                        to this privacy notice where required. However, we would recommend that you look back at this notice
                                        from time to time to check for any updates. </p>


                                    <br class="t-last-br" />
                                    <br class="t-last-br" />
                                    <br class="t-last-br" />
                                    <br class="t-last-br" />
                                    <br class="t-last-br" />

                                </section>


                            </div>
                        </section>
                    </div>
                </section>
            </div>

        </div>
    )
}

export default PrivacyAndPolicy